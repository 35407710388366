import React from "react";
import Body from "../components/Body/Body";
import BlogImage from "../assets/img/blog.png";

function BlogPage() {
  return (
    <Body>
      <div className="top-0 min-h-[50vh] lg:min-h-[60vh] w-[100vw] md:flex md:justify-between md:items-center">
        <picture>
          <source srcSet={BlogImage} type="image/jpg" />
          <img
            src={BlogImage}
            alt="Working on this"
            className="w-[100%] h-[50vh] object-cover opacity-80 md:opacity-100 relative z-[1] md:w-[60%] lg:w-[70%] md:object-cover md:z-0"
          />
        </picture>
        {/* Displaying a Title of the Blog */}
        <div className="min-h-[20vh] flex flex-row items-center text-center">
          <h1 className="text-[#eee5e5] text-[4em] text-center">Latest Projects Done</h1>
        </div>
          {/* carta */}
        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <img
  
            alt=""
          />

          <div className="p-5">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Noteworthy technology acquisitions 2021
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Here are the biggest enterprise technology acquisitions of 2021 so
              far, in reverse chronological order.
            </p>
            {/* <a href="#" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"> */}
            Read more
            <svg
              aria-hidden="true"
              className="w-4 h-4 ml-2 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            {/* </a> */}
          </div>
        </div>
      </div>
    </Body>
  );
}

export default BlogPage;
